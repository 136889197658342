import { useMemo } from 'react';

import Paragraph from '@tiptap/extension-paragraph';
import Heading from '@tiptap/extension-heading';
import Text from '@tiptap/extension-text';
import FileHandler from '@tiptap-pro/extension-file-handler';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import CodeBlock from '@tiptap/extension-code-block';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import BulletList from '@tiptap/extension-bullet-list';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Blockquote from '@tiptap/extension-blockquote';
import Strike from '@tiptap/extension-strike';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Underline from '@tiptap/extension-underline';
import Color from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import Code from '@tiptap/extension-code';
import Highlight from '@tiptap/extension-highlight';
import DropCursor from '@tiptap/extension-dropcursor';
import Focus from '@tiptap/extension-focus';
import History from '@tiptap/extension-history';
import TextStyle from '@tiptap/extension-text-style';
import UniqueID from '@tiptap-pro/extension-unique-id';

import Details from '@tiptap-pro/extension-details';
import DetailsContent from '@tiptap-pro/extension-details-content';
import DetailsSummary from '@tiptap-pro/extension-details-summary';

import { Example } from './extensions/example';
import { Warning } from './extensions/warning';
import { KeyTakeAways } from './extensions/key-takeaways';
import { PracticalTips } from './extensions/practical-tips';
import { DiscussionPrompt } from './extensions/discussion-prompt';
import { Article } from './extensions/article';
import { Embed } from './extensions/embed';

import { Image } from './extensions/image';
import { ImageBlock } from './extensions/image-block';
import { ImageUpload } from './extensions/image-upload';

// functionality extensions
import TextAlign from '@tiptap/extension-text-align';
import ListKeyMap from '@tiptap/extension-list-keymap';
import Placeholder from '@tiptap/extension-placeholder';

import { SlashCommand } from './extensions/SlashCommand';
import { DocumentBlock } from './extensions/document-block';
import { DocumentUpload } from './extensions/document-upload';
import { SupportPopup } from './extensions/support-popup';
import { Column, Columns } from './extensions/multi-column';
import { Table, TableCell, TableHeader, TableRow } from './extensions/table';
import { Document } from './extensions/document';
import { useTranslation } from 'react-i18next';
import AILoader from './extensions/ai-loader/ai-loader';
import { AIFunctions } from './extensions/ai-functions';
import { AITextActions } from './block-editor';
import { EmbedLoader } from './extensions/embed-loader/embed-loader';

export const useExtensions = ({
  placeholder,
  companyId,
  documentsEnabled,
  onOpenDiscussionClick,
  onSupportClick,
  aiTextActions,
}: {
  placeholder?: string;
  companyId: string;
  documentsEnabled: boolean;
  onOpenDiscussionClick: () => void;
  onSupportClick: () => void;
  aiTextActions: AITextActions;
}) => {
  const { t: tSlash } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.slash-command',
  });
  const { t: tWarning } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.extensions.warning',
  });
  const { t: tExample } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.extensions.example',
  });
  const { t: tKeyTakeAways } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.extensions.key-takeaways',
  });
  const { t: tDiscuss } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.extensions.discuss',
  });
  const { t: tPracticalTips } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.extensions.practical-tips',
  });
  const { t: tAItools } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.extensions.ai-tools',
  });
  const { t: tEmbedLoader } = useTranslation('translation', {
    keyPrefix: 'components.tiptap.extensions.embed-loader',
  });

  const i18n = useMemo(
    () => ({
      slashCommand: {
        h1: {
          title: tSlash('h1.title'),
        },
        h2: {
          title: tSlash('h2.title'),
        },
        h3: {
          title: tSlash('h3.title'),
        },
        h4: {
          title: tSlash('h4.title'),
        },
        bulletList: {
          title: tSlash('bulletList.title'),
          description: tSlash('bulletList.description'),
        },
        numberedList: {
          title: tSlash('numberedList.title'),
          description: tSlash('numberedList.description'),
        },
        codeBlock: {
          title: tSlash('codeBlock.title'),
        },
        discussion: {
          title: tSlash('discussion.title'),
          description: tSlash('discussion.description'),
        },
        example: {
          title: tSlash('example.title'),
          description: tSlash('example.description'),
        },
        image: {
          title: tSlash('image.title'),
          description: tSlash('image.description'),
        },
        table: {
          title: tSlash('table.title'),
          description: tSlash('table.description'),
        },
        taskList: {
          title: tSlash('taskList.title'),
          description: tSlash('taskList.description'),
        },
        warning: {
          title: tSlash('warning.title'),
          description: tSlash('warning.description'),
        },
        keyTakeAways: {
          title: tSlash('keyTakeAways.title'),
          description: tSlash('keyTakeAways.description'),
        },
        practicalTips: {
          title: tSlash('practicalTips.title'),
          description: tSlash('practicalTips.description'),
        },
        horizontalRule: {
          title: tSlash('horizontalRule.title'),
          description: tSlash('horizontalRule.description'),
        },
        document: {
          title: tSlash('document.title'),
          description: tSlash('document.description'),
        },
        columns: {
          title: tSlash('columns.title'),
          description: tSlash('columns.description'),
        },
        blockQuote: {
          title: tSlash('blockquote.title'),
          description: tSlash('blockquote.description'),
        },
        vimeo: {
          title: 'Vimeo',
          description: tSlash('vimeo.description'),
        },
        youtube: {
          title: 'YouTube',
          description: tSlash('youtube.description'),
        },
        genially: {
          title: 'Genially',
          description: tSlash('genially.description'),
        },
        outgrow: {
          title: 'Outgrow',
          description: tSlash('outgrow.description'),
        },
        miro: {
          title: 'Miro',
          description: tSlash('miro.description'),
        },
        gfycat: {
          title: 'Gfycat',
          description: tSlash('gfycat.description'),
        },
        imgur: {
          title: 'Imgur',
          description: tSlash('imgur.description'),
        },
        nepazing: {
          title: 'Nepazing',
          description: tSlash('nepazing.description'),
        },
        synthesia: {
          title: 'Synthesia',
          description: tSlash('synthesia.description'),
        },
        hubspot: {
          title: 'HubSpot',
          description: tSlash('hubspot.description'),
        },
        integrationsTitle: tSlash('integrations-title'),
      },
      warning: {
        defaultTitle: tWarning('default-title'),
      },
      discuss: {
        defaultTitle: tDiscuss('default-title'),
      },
      keyTakeAways: {
        defaultTitle: tKeyTakeAways('default-title'),
      },
      practicalTips: {
        defaultTitle: tPracticalTips('default-title'),
      },
      example: {
        defaultTitle: tExample('default-title'),
      },
      aiTools: {
        accept: tAItools('accept'),
        reject: tAItools('reject'),
        suggestionTitle: tAItools('suggestion-title'),
      },
      embedLoader: {
        errorLabel: tEmbedLoader('error-label'),
        addButtonLabel: tEmbedLoader('add-button-label'),
        placeholderLabel: (type: string) => tEmbedLoader('placeholder-label', { type }),
      },
    }),
    [tAItools, tDiscuss, tEmbedLoader, tExample, tKeyTakeAways, tPracticalTips, tSlash, tWarning],
  );
  const extensions = useMemo(
    () => [
      Document,
      Paragraph.configure({
        HTMLAttributes: {
          class: 'font-lexend text-text-01 text-left mb-3 w-full outline-none content-editable',
        },
      }),
      Heading.configure({
        levels: [1, 2, 3, 4],
        HTMLAttributes: {
          class: 'font-lexend text-text-01 mb-2 mt-4 outline-none',
        },
      }),
      Text,
      HorizontalRule.configure({
        HTMLAttributes: {
          class: 'my-8 w-full',
        },
      }),

      Strike,
      Bold,
      Italic,
      Subscript,
      Superscript,
      Code,
      Underline,
      Link,
      Color,
      Highlight.configure({ multicolor: true }),
      TextStyle,
      Column,
      Columns,

      ListItem,
      OrderedList,
      BulletList,
      Article,

      DocumentBlock(documentsEnabled),
      DocumentUpload(documentsEnabled),

      FileHandler.configure({
        allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
        onDrop: (currentEditor, files, pos) => {
          files.forEach((file) => {
            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              currentEditor
                .chain()
                .insertContentAt(pos, {
                  type: 'image',
                  attrs: {
                    src: fileReader.result,
                  },
                })
                .focus()
                .run();
            };
          });
        },
        onPaste: (currentEditor, files, htmlContent) => {
          files.forEach((file) => {
            if (htmlContent) {
              // if there is htmlContent, stop manual insertion & let other extensions handle insertion via inputRule
              // you could extract the pasted file from this url string and upload it to a server for example
              console.log(htmlContent); // eslint-disable-line no-console
              return false;
            }

            const fileReader = new FileReader();

            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              currentEditor
                .chain()
                .insertContentAt(currentEditor.state.selection.anchor, {
                  type: 'image',
                  attrs: {
                    src: fileReader.result,
                  },
                })
                .focus()
                .run();
            };

            return true;
          });
        },
      }),

      Details.configure({
        persist: true,
      }),
      DetailsSummary,
      DetailsContent,

      Blockquote.configure({
        HTMLAttributes: {
          class: 'my-4',
        },
      }),

      TaskList,
      TaskItem.configure({
        nested: true,
        onReadOnlyChecked: () => true,
      }),

      CodeBlock.configure({
        HTMLAttributes: {
          class: 'bg-surface-bg p-6 my-4 text-text-01 font-lexend rounded-xl',
        },
      }),

      // Discussion prompt
      DiscussionPrompt(i18n.discuss, onOpenDiscussionClick),

      // Table
      Table.configure({
        HTMLAttributes: {
          class: '!my-4',
        },
      }),
      TableHeader,
      TableRow,
      TableCell,

      Example(i18n.example),
      Warning(i18n.warning),
      KeyTakeAways(i18n.keyTakeAways),

      Embed,
      EmbedLoader(i18n.embedLoader),

      // Image.configure({
      //   allowBase64: true,
      // }),
      ImageBlock.configure({
        allowBase64: true,
      }),
      ImageUpload.configure({
        companyId: companyId,
      }),

      PracticalTips(i18n.practicalTips),

      // Functionality extensions
      ...(placeholder
        ? [
            Placeholder.configure({
              placeholder: placeholder,
            }),
          ]
        : []),
      ListKeyMap.configure({
        listTypes: [
          {
            itemName: 'listItem',
            wrapperNames: ['bulletList', 'orderedList'],
          },
          {
            itemName: 'taskItem',
            wrapperNames: ['taskList'],
          },
        ],
      }),
      Focus,
      SupportPopup(onSupportClick),
      SlashCommand(companyId, documentsEnabled, onSupportClick, i18n.slashCommand),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      DropCursor,
      UniqueID.configure({
        types: [
          Image.name,
          ImageBlock.name,
          Embed.name,
          Example(i18n.example).name,
          KeyTakeAways(i18n.keyTakeAways).name,
          BulletList.name,
          OrderedList.name,
          PracticalTips(i18n.practicalTips).name,
          Warning(i18n.warning).name,
          Table.name,
          DiscussionPrompt(i18n.discuss, () => null).name,
          CodeBlock.name,
          TaskList.name,
          Blockquote.name,
          Details.name,
          Paragraph.name,
          Heading.name,
          DocumentBlock(false).name,
          Article.name,
          HorizontalRule.name,
        ],
      }),
      History,

      // AI
      AILoader({
        accept: i18n.aiTools.accept,
        suggestion: i18n.aiTools.suggestionTitle,
        reject: i18n.aiTools.reject,
      }),
      AIFunctions(aiTextActions),
    ],
    [
      documentsEnabled,
      i18n.discuss,
      i18n.example,
      i18n.warning,
      i18n.keyTakeAways,
      i18n.embedLoader,
      i18n.practicalTips,
      i18n.slashCommand,
      i18n.aiTools.accept,
      i18n.aiTools.suggestionTitle,
      i18n.aiTools.reject,
      onOpenDiscussionClick,
      companyId,
      placeholder,
      onSupportClick,
      aiTextActions,
    ],
  );

  return extensions;
};
